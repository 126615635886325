@use '../../assets/helpers/theme-variables' as v;
@import '../../assets/helpers/mixins';

.modal-content {
    border: 0;
    background: #F4F7F9;
    border-radius: 3;
    padding: 50px;
    .modal-header {
        padding: 0;
        border: 0;
        margin-block-end: 30px;
        p {
            a {
                display: inline-block;
                margin-inline-start: 5px;
                margin-block-start: -5px;
                color: v.$primary;
            }
        }
        .btn-close {
            margin: 0;
        }
    }
    .modal-body {
        .login {
            form {
                .input-group {
                    position: relative;
                    .form-label {
                        position: absolute;
                        z-index: 1;
                        right: 20px;
                        top: -15px;
                        background: #f5f7f9;
                        padding: 5px;
                        color: v.$theme-black;
                        font-size: v.$font-size-18;
                        font-weight: 500;
                    }
                    .form-control {
                        background-color: transparent;
                        border: 1px solid #E1E3E3;
                        border-radius: 12px !important;
                        padding: 20px 30px;
                        &:hover, &:focus {
                            box-shadow: none;
                        }
                    }
                    i {
                        @include vertical-position();
                        left: 30px;
                        font-size: v.$font-size-18;
                        &.icon-lock {
                            font-size: v.$font-size-h4
                        }
                    }
                }
                .error {
                    margin-block-start: -10px;
                    margin-block-end: 30px;
                }
                .login-submit {
                    display: flex;
                    justify-content: center;
                    background: v.$primary;
                    border-radius: 24px;
                    width: 100%;
                    padding: 15px 20px 20px;
                    border: 0;
                    margin-block-start: 30px;
                    font-size: v.$font-size-h4;
                    color: v.$white;
                }
            }
        }
    }
}