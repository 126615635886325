@use "../../assets/helpers/theme-variables" as v;
@import "../../assets/helpers/mixins";

.modal-content {
  border: 0;
  background: #f4f7f9;
  border-radius: 3;
  padding: 50px;
  .modal-header {
    padding: 0;
    border: 0;
    margin-block-end: 30px;
    .btn-close {
      margin: 0;
    }
  }
  .modal-body {
    .login {
      form {
        .input-group {
          position: relative;
          .form-label {
            position: absolute;
            z-index: 1;
            right: 20px;
            top: -15px;
            background: #f5f7f9;
            padding: 5px;
            color: v.$theme-black;
            font-size: v.$font-size-18;
            font-weight: 500;
          }
          .form-control {
            background-color: transparent;
            border: 1px solid #e1e3e3;
            border-radius: 12px !important;
            padding: 20px 30px;
            &:hover,
            &:focus {
              box-shadow: none;
            }
          }
          i {
            @include vertical-position();
            left: 30px;
            font-size: v.$font-size-18;
            &.icon-lock {
              font-size: v.$font-size-h4;
            }
          }
        }
        .error {
          margin-block-start: -10px;
          margin-block-end: 30px;
        }
        .login-submit {
          display: flex;
          justify-content: center;
          background: v.$primary;
          border-radius: 24px;
          width: 100%;
          padding: 15px 20px 20px;
          border: 0;
          margin-block-start: 30px;
          font-size: v.$font-size-h4;
          color: v.$white;
        }
      }
    }
    .hksa-social-login {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      padding-block: 15px;
      .hksa-separator-login {
        position: relative;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        p {
          position: relative;
          width: auto;
          text-align: center;
          color: v.$text;
          background-color: #f4f7f9;
          padding-inline: 10px;
          margin-block-start: 0;
          font-size: v.$font-size-18;
          z-index: 1;
        }
        &::after {
          content: "";
          display: block;
          position: absolute;
          background: #E1E3E3;
          width: 100%;
          height: 2px;
          @include vertical-position();
        }
      }
      .hksa-social-login-item {
        display: flex;
        width: 100%;
        align-items: center;
        flex-wrap: wrap;
        margin-block-start: 15px;
        .hksa-google-login {
          display: flex;
          align-items: center;
          width: 100%;
          margin-block-start: 15px;
          > div {
            width: 100% !important;
          }
          iframe {
            width: 100% !important;
            margin: auto !important;
            padding: 0 25px !important;
          @media (max-width: 575px) {
              width: 110% !important;
              margin: auto -15px !important;
              padding: 0 0px !important;
            }
            > div {
              width: 100% !important;
            }
            body {
              display: flex;
            }
            .nsm7Bb-HzV7m-LgbsSe {
            }
          }
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row-reverse;
            background: #ffffff;
            border-radius: 30px;
            color: v.$text;
            font-size: v.$font-size-16;
            padding: 15px;
            width: 82%;
            margin: auto;
            border: 1px solid transparent;
            @include transition-ease-out-4();
            div {
              padding: 0 !important;
            }
            span {
              width: auto;
              padding: 0 !important;
              color: v.$text !important;
              font-size: v.$font-size-20 !important;
              svg {
                @include transition-ease-out-4();
              }
            }
            @include transition-ease-out-4();
            i {
              margin-inline-start: 10px;
              font-size: v.$font-size-18;
              &::before {
                color: #345089;
                @include transition-ease-out-4();
              }
            }
            img {
              width: 18px;
              margin-inline-start: 10px;
            }
            &:hover {
              background-color: v.$primary !important;
              color: v.$white;
              @include transition-ease-out-4();
              svg {
                background-color: v.$primary !important;
                @include transition-ease-out-4();
              }
              div {
                background: transparent !important;
              }
              span {
                color: v.$white !important;
              }
              i {
                &::before {
                  color: v.$white;
                  @include transition-ease-out-4();
                }
              }
            }
          }
        }
        span {
          display: flex;
          align-items: center;
          width: 100%;
          margin-block-start: 15px;
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row-reverse;
            background: #ffffff;
            border-radius: 30px;
            color: v.$text;
            font-size: v.$font-size-16;
            padding: 15px;
            width: 82%;
            margin: auto;
            border: 1px solid transparent;
            @include transition-ease-out-4();
            i {
              margin-inline-start: 10px;
              font-size: v.$font-size-18;
              &::before {
                color: #345089;
                @include transition-ease-out-4();
              }
            }
            &:hover {
              background-color: #345089;
              color: v.$white;
              @include transition-ease-out-4();
              i {
                &::before {
                  color: v.$white;
                  @include transition-ease-out-4();
                }
              }
            }
          }
        }
        > div {
          display: flex;
          align-items: center;
          width: 100%;
          margin-block-start: 15px;
          .twitter-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row-reverse;
            background: #ffffff;
            border-radius: 30px;
            color: v.$text;
            font-size: v.$font-size-16;
            padding: 15px;
            width: 82%;
            margin: auto;
            border: 1px solid transparent;
            @include transition-ease-out-4();
            i {
              margin-inline-start: 10px;
              font-size: v.$font-size-20;
              &::before {
                color: #1d9bf1;
                @include transition-ease-out-4();
              }
            }
            &:hover {
              background-color: #1d9bf1;
              color: v.$white;
              @include transition-ease-out-4();
              i {
                &::before {
                  color: v.$white;
                  @include transition-ease-out-4();
                }
              }
            }
          }
        }
      }
    }
  }
}
