.hksa-page-content {
    padding-block: 20px;
    .offset-md-1 {
        @media only screen and (min-width: 960px) {
            margin-inline-end: 3.333333%;
            width: 71.66666667%;
        }
        @media (max-width: 992px) {
            margin-inline-end: 0px;
            width: 100%;
        }
        @media only screen and (min-width: 959px) and (max-width: 1300px) {
            margin-inline-end: 0;
        }
    }
    .col-md-3 {
        @media (max-width: 992px) {
            margin-inline-end: 0px;
            width: 100%;
        }
        @media only screen and (min-width: 959px) and (max-width: 1300px) {
            width: 28%;
        }
    }
}

.single-post {
    .offset-md-1 {
        @media only screen and (min-width: 960px) {
            margin-inline-end: 3.333333%;
            width: 71.66666667%;
        }
        @media (max-width: 992px) {
            margin-inline-end: 0px;
            width: 100%;
        }
    }
    .col-md-3 {
        @media (max-width: 992px) {
            margin-inline-end: 0px;
            width: 100%;
        }
    }
}