@use '../../assets/helpers/theme-variables' as v;

//RESET CSS FILES CODE
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

:focus {
  outline: 0 !important;
}

.fit-vids-style {
  display: none;
}

figure {
  margin: 0;
  padding: 0;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  border: 0px none;
}

iframe {
  border: none;
}

::selection {
  background-color: v.$primary;
  /* Safari */
  color: $white;
}

::-moz-selection {
  background-color: v.$primary;
  /* Firefox */
  color: $white;
}

.error {
  color: red;
}

/*-----------------------------------------------------------------------------------*/
/*	GENERAL
/*-----------------------------------------------------------------------------------*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: middle;
  list-style: none;
  word-break: break-word;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -font-smoothing: antialiased;
  font-family: "sans-serif",'DIN NEXTTM ARABIC';
}

body {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  color: #7a7a7a;
  line-height: 1;
  font-size: 14px;
  font-weight: 400;
  background-color: $white;
}

.overflow-hidden {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

ul,
ol {
  list-style: none;
  margin-bottom: 0px !important;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $theme-black;
  clear: both;
  font-weight: 600;
  text-align: inherit;
  margin: 0;
  line-height: 1;
  position: relative;
  margin-bottom: 0;
}

.h1,
h1 {
  font-size: $font-size-h1;
}

.h2,
h2 {
  font-size: $font-size-h2;
}

.h3,
h3 {
  font-size: $font-size-h3;
}

.h4,
h4 {
  font-size: $font-size-h4;
}

.h5,
h5 {
  font-size: $font-size-h5;
}

.h6,
h6 {
  font-size: $font-size-h6;
}

.inner {
  padding-top: 70px;
  padding-bottom: 70px;
}

.inner-title {
  padding-top: 40px;
  padding-bottom: 40px;
}

.inner-f {
  padding-top: 50px;
  padding-bottom: 50px;
}

.inner-fluid {
  padding-bottom: 50px;
}

.inner-Pages {
  padding-top: 60px;
  padding-bottom: 60px;
}

.none-padding {
  padding: 0 !important;
}

.fixed-section {
  position: fixed;
  width: 33.333%;
  top: 0;
}

.margin-0 {
  margin: 0 !important;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

span,
p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 1;
}

p {
  display: block;
  color: v.$text;
  width: 100%;
  margin: 10px auto;
  line-height: 1.5;
}

a {
  text-decoration: none !important;
  @include transition-ease-out-4();
  color: v.$text;

  &:hover,
  &:focus {
    text-decoration: none !important;
    @include transition-ease-out-4();
    color: v.$primary;
    border: none !important;
    box-shadow: none !important;
  }
}

b,
strong {
  font-weight: 700 !important;
}

.btn {
  border: none !important;
  box-shadow: none !important;
}

.btn.focus,
.btn:focus,
.btn:hover {
  color: $white;
  text-decoration: none;
  border: none !important;
  box-shadow: none !important;
}

.z-index {
  position: relative;
  z-index: 2;
}

.zindex-0 {
  z-index: 0 !important;
}