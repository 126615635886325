@use "../../../assets/helpers/theme-variables" as v;
@import "../../../assets/helpers/mixins";

.hksa-add-event-wrapper {
  .hksa-add-event--title {
    padding-block: 50px;
    h3 {
      font-size: 38px;
      color: v.$theme-black;
      font-weight: 500;
      margin-block-end: 15px;
    }
    p {
      font-size: 24px;
      color: v.$text;
    }
  }
  .hksa-add-event {
    padding-block-end: 80px;
    .input-groups {
      display: flex;
      align-items: center;
      gap: 30px;
      @media (max-width: 575px) {
        flex-wrap: wrap;
        gap: 15px;
      }
    }
    .input-group {
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 575px) {
        margin-block-end: 15px !important;
      }
      label {
        display: flex;
        width: 100%;
        color: #202124;
        font-size: 20px;
        margin-block-end: 20px;
      }
      .input-with-icon {
        position: relative;
        display: flex;
        width: 100%;
      }
      input {
        width: 100%;
        border: 1px solid #e1e3e3;
        border-radius: 12px !important;
        background: transparent;
        padding: 15px;
        direction: ltr;
        text-align: end;
      }
      #location {
        background-color: v.$white;
        border-radius: 18px 18px 0 0 !important;
      }
      select {
        width: 100%;
        border: 1px solid #e1e3e3;
        border-radius: 12px !important;
        background: transparent;
        padding: 15px;
      }
      textarea {
        border: 1px solid #e1e3e3;
        border-radius: 12px !important;
        background: transparent;
        padding: 15px;
      }
      i {
        position: absolute;
        left: 15px;
        top: 16px;
        font-size: 22px;
      }
      .input-error {
        display: inline-block;
        width: 100%;
        color: #d41a1a;
        font-size: 14px;
        margin: 4px 0 8px 0;
      }
    }

    .select-custom {
      .css-13cymwt-control {
        border: 1px solid #E7E7E7;
        background: transparent;
        padding: 9px;
        border-radius: 12px;
        .css-1jqq78o-placeholder {
          font-size: 18px;
        }
        &:focus {
          border: 0;
        }
      }
      .css-t3ipsp-control {
        border: 1px solid #E7E7E7;
        background: transparent;
        padding: 9px;
        border-radius: 12px;
        .css-1jqq78o-placeholder {
          font-size: 18px;
        }
        &:focus {
          border: 0;
        }
      }
      .css-1nmdiq5-menu {
        z-index: 1111 !important;
      }
    }
    .drag-file-area {
      border: 1px solid #e2e3e3;
      border-radius: 16px;
      width: 350px;
      text-align: center;
      .upload-icon {
        font-size: 50px;
      }
      h3 {
        font-size: 26px;
        margin: 15px 0;
      }
      label {
        cursor: pointer;
        margin: 10px 0 15px;
        padding: 30px 50px;
        font-size: 19px;
        .browse-files-text {
          color: v.$text;
          font-weight: bolder;
          cursor: pointer;
        }
        i {
          font-size: 32px;
        }
      }
      .browse-files {
        margin-block-start: 20px;
        span {
          position: relative;
        }
      }
      .hksa-preview-upload {
        position: relative;
        margin-block-start: 20px;
        .modal-header {
          position: absolute;
          right: 10px;
          top: 10px;
          button {
            opacity: 1;
            background: #fff
              url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e")
              center/0.5em auto no-repeat;
          }
        }
      }
      .default-file-input {
        opacity: 0;
        display: none;
      }
    }

    .input-upload {
      label {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        color: #202124;
        font-size: 20px;
        margin-block-end: 20px;
        justify-content: center;
        &.form-label {
          justify-content: flex-start;
        }
      }
    }

    .form-group-title {
      h4 {
        display: flex;
        width: 100%;
        color: #202124;
        font-size: 20px;
        margin-block-end: 20px;
        font-weight: 400;
      }
    }

    .add-submit {
      background: #059e4b 0% 0% no-repeat padding-box;
      border-radius: 24px;
      padding: 15px 130px;
      border: 0;
      color: #fff;
      font-size: 24px;
      line-height: 1.5;
    }
  }
  .form-group-tags {
    .hksa-tags-list {
      .hksa-tags-item {
        input {
          display: none;
          opacity: 0;
        }
        [type="checkbox"]:checked + .checkbox-container {
          border: solid 1px v.$blue-light;
          background-color: v.$blue-light;
          color: v.$primary;
          p {
            color: v.$primary;
          }
        }
        .checkbox-container {
          display: flex;
          border: 1px solid #e7e7e7;
          padding: 0px 25px;
          border-radius: 20px;
          margin-inline-end: 10px;
          margin-block-end: 10px;
          cursor: pointer;
          @include transition-ease-out-4();
          p {
            font-size: v.$font-size-20;
            color: v.$text;
          }
          &:hover {
            background-color: v.$blue-light;
            border-color: v.$blue-light;
            @include transition-ease-out-4();
            p {
              color: v.$primary;
              @include transition-ease-out-4();
            }
          }
        }
      }
    }
  }
  .form-group-social {
    .input-group {
      input {
        padding-inline-end: 55px;
      }
      img {
        position: absolute;
        right: 15px;
        left: auto;
        width: 29px;
        top: 13px;
      }
      i {
        right: 15px;
        left: auto;
        font-size: 26px;
        top: 15px;
      }
    }
  }
  .hksa-overflow-submit {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    background: rgb(0 0 0 / 44%);
    opacity: 0;
    visibility: hidden;
    &.active {
      opacity: 1;
      visibility: visible;
    }
    .hksa-overflow-submit-text {
      position: relative;
      @include vertical-position();
      margin: auto;
      right: 0;
      left: 0;
      text-align: center;
    }
    p {
      font-size: v.$font-size-20;
      color: v.$white;
    }
    .loader {
      width: 48px;
      height: 48px;
      border: 5px solid #fff;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
