@font-face {
    font-family: 'DIN NEXTTM ARABIC';
    src: url('./DINNextLTW23-Regular.woff2') format('woff2'),
        url('./DINNextLTW23-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN NEXTTM ARABIC';
    src: url('./DINNextLTW23-Medium.woff2') format('woff2'),
        url('./DINNextLTW23-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN NEXTTM ARABIC';
    src: url('./DINNextLTW23-Light.woff2') format('woff2'),
        url('./DINNextLTW23-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN NEXTTM ARABIC';
    src: url('./DINNextLTW23-Bold2.woff2') format('woff2'),
        url('./DINNextLTW23-Bold2.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

