@use "../../assets/helpers/theme-variables" as v;
@import "../../assets/helpers/mixins";

.hksa-news-block {
  position: relative;
  border-radius: 18px;
  overflow: hidden;
  .post-image-warpper {
    display: block;
    background-image: url("../../assets/img/hashksa-placeholder.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 18px;
    .post-image {
      width: 100%;
      min-height: 360px;
      object-fit: cover;
    }
  }
  &.hksa-news-block--with-video {
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background: #000000d1;
      border-radius: 18px;
    }
  }
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background: transparent
      linear-gradient(
        0deg,
        #000000d1 0%,
        #181818b8 35%,
        #4c4c4c00 82%,
        #5454540d 100%
      )
      0% 0% no-repeat padding-box;
    border-radius: 18px;
  }
  .hksa-news-block-content {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: space-between;
    width: 100%;
    height: 100%;
    padding: 30px;
    z-index: 1;
    .hksa-news-block-header {
      display: flex;
      width: 100%;
      .hksa-play {
        display: flex;
        align-items: center;
        justify-content: center;
        @include vertical-position();
        right: 0;
        left: 0;
        margin: auto;
        background: v.$white;
        width: 78px;
        height: 78px;
        border-radius: 100%;
        z-index: 11;
        i {
          font-size: v.$font-size-20;
        }
      }
      .hksa-news-item--cat {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 15px 30px;
        background: rgba(1, 0, 0, 0.38);
        border-radius: 28px;
        opacity: 1;
        backdrop-filter: blur(7px);
        -webkit-backdrop-filter: blur(7px);
        opacity: 1;
        backdrop-filter: blur(7px);
        -webkit-backdrop-filter: blur(7px);
        justify-content: center;
        text-align: center;
        img {
          max-width: 30px;
          margin-inline-end: 15px;
        }
        span {
          color: v.$white;
          font-size: v.$font-size-20;
        }
      }
      .hksa-post-bookmark {
        position: absolute;
        left: 30px;
        top: 25px;
        z-index: 1;
        cursor: pointer;
        i {
          font-size: 70px;
          background: rgba(1, 0, 0, 0.38);
          backdrop-filter: blur(7px);
          -webkit-backdrop-filter: blur(7px);
          opacity: 1;
          backdrop-filter: blur(7px);
          -webkit-backdrop-filter: blur(7px);
          border-radius: 14px;
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &.active {
          i {
            &:before {
              color: v.$primary;
            }
          }
        }
      }
    }
    .hksa-news-block-footer {
      width: 100%;
      .hksa-news-block-footer-info {
        @include transition-ease-out-4();
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 60px, 0);
      }
      .hksa-news-block-meta {
        span {
          display: flex;
          align-items: center;
          color: v.$white;
          font-size: v.$font-size-16;
          span {
            margin-inline-start: 10px;
          }
        }
      }
      .hksa-news-title {
        font-size: v.$font-size-h3;
        color: v.$white;
        line-height: 1.5;
        margin-block: 10px;
        @media only screen and (min-width: 959px) and (max-width: 1300px) {
          font-size: 2vw;
        }
        @media (max-width: 575px) {
          font-size: v.$font-size-18;
        }
      }
      .hksa-news-meta-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-block: 30px 0;
        @include transition-ease-out-4();
        -webkit-transform: translate3d(0, 200%, 0);
        transform: translate3d(0, 200%, 0);
        .hksa-news-meta {
          display: flex;
          align-items: center;
          flex: 1;
          a {
            width: 100%;
            margin: 10px auto;
          }
          .hksa-news-meta-item {
            display: flex;
            align-items: center;
            font-size: v.$font-size-16;
            color: v.$white;
            width: 100%;
            i {
              font-size: v.$font-size-20;
              margin-inline-end: 10px;
              &:before {
                color: v.$white;
              }
              &.icon-views {
                font-size: 14px;
              }
              &.icon-comments {
                font-size: 16px;
              }
            }
            img {
              max-width: 20px;
              margin-inline-end: 10px;
            }
            &.like {
              cursor: pointer;
            }
            &.active {
              i {
                &:before {
                  color: v.$primary;
                }
              }
            }
            &.hksa-share-post {
              background-color: rgb(65 65 65 / 57%);
              border-radius: 14px;
              width: 60px;
              height: 60px;
              align-items: center;
              text-align: center;
              justify-content: center !important;
              margin-inline-end: 0;
              margin-block: 0;
              i {
                margin: 0;
              }
            }
          }
          .hksa-news-share-item {
            opacity: 0;
            visibility: hidden;
            @include transition-ease-out-4();
            -webkit-transform: translate3d(0, 200%, 0);
            transform: translate3d(0, 200%, 0);
            &.active {
              opacity: 1;
              visibility: visible;
              @include transition-delay();
              -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
            }
          }
        }
      }
    }
    &:hover {
      .hksa-news-block-footer {
        .hksa-news-block-footer-info {
          @include transition-delay();
          -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
        }
        .hksa-news-meta-block {
          @include transition-delay();
          -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }
}

.hksa-pagination {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-block-start: 50px;
  li {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      padding: 10px;
      background-color: v.$blue-light;
    }
    &.selected,
    &.active {
      a,
      .page-link {
        background-color: v.$primary;
        color: v.$white;
      }
    }
    &:first-child {
      .page-link {
        width: 70px;
      }
    }
    &:last-child {
      .page-link {
        width: 70px;
      }
    }
  }
}

.hksa-news-masonry-wrapper {
  position: relative;
  .hksa-news-filter {
    .input-groups {
      display: flex;
      align-items: center;
      gap: 30px;
      @media (max-width: 575px) {
        flex-wrap: wrap;
        gap: 15px;
      }
    }
    .input-group {
      display: flex;
      flex-wrap: wrap;
      @media (max-width: 575px) {
        margin-block-end: 15px !important;
      }
      .select-custom {
        .css-13cymwt-control {
          border: 1px solid #E7E7E7;
          background: transparent;
          padding: 9px;
          border-radius: 55px;
          .css-1jqq78o-placeholder {
            font-size: 18px;
          }
          &:focus {
            border: 0;
          }
        }
        .css-t3ipsp-control {
          border: 1px solid #E7E7E7;
          background: transparent;
          padding: 9px;
          border-radius: 55px;
          .css-1jqq78o-placeholder {
            font-size: 18px;
          }
          &:focus {
            border: 0;
          }
        }
        .css-1nmdiq5-menu {
          z-index: 1111 !important;
        }
      }
      label {
        display: flex;
        width: 100%;
        color: #202124;
        font-size: 20px;
        margin-block-end: 20px;
      }
      .input-with-icon {
        position: relative;
        display: flex;
        width: 100%;
      }
      input {
        width: 100%;
        border: 1px solid #e1e3e3;
        border-radius: 12px !important;
        background: transparent;
        padding: 15px;
        direction: ltr;
        text-align: end;
      }
      #location {
        background-color: v.$white;
        border-radius: 18px 18px 0 0 !important;
      }
      select {
        width: 100%;
        border: 1px solid #e1e3e3;
        border-radius: 12px !important;
        background: #FFF;
        padding: 15px;
        background: url("../../assets/img/down-chevron-dark.svg") no-repeat left 5%
          center;
        background-size: 15px;
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        -ms-appearance: none;
        appearance: none;
      }
      textarea {
        border: 1px solid #e1e3e3;
        border-radius: 12px !important;
        background: transparent;
        padding: 15px;
      }
      i {
        position: absolute;
        left: 15px;
        top: 16px;
        font-size: 22px;
      }
      .input-error {
        display: inline-block;
        width: 100%;
        color: #d41a1a;
        font-size: 14px;
        margin: 4px 0 8px 0;
      }
      .add-submit {
        background: #059e4b 0% 0% no-repeat padding-box;
        border-radius: 12px;
        padding: 7px 40px 12px;
        width: 100%;
        border: 0;
        color: #fff;
        font-size: 24px;
        line-height: 1.5;
      }
    }
  }
  .loading {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: rgb(255 255 255 / 80%);
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    min-height: 100vh;
    .hksa-overflow-submit-text {
      position: relative;
      @include vertical-position();
      margin: auto;
      right: 0;
      left: 0;
      text-align: center;
      .loader {
        width: 48px;
        height: 48px;
        border: 5px solid v.$primary;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
      }

      @keyframes rotation {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
}
