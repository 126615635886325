@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?bljm56');
  src:  url('fonts/icomoon.eot?bljm56#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?bljm56') format('truetype'),
    url('fonts/icomoon.woff?bljm56') format('woff'),
    url('fonts/icomoon.svg?bljm56#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add-photo:before {
  content: "\e915";
  color: #777e90;
}
.icon-reply:before {
  content: "\e912";
  color: #777e90;
}
.icon-send:before {
  content: "\e913";
  color: #059e4b;
}
.icon-close:before {
  content: "\e914";
  color: #777e90;
}
.icon-user:before {
  content: "\e90f";
  color: #777e90;
}
.icon-phone:before {
  content: "\e910";
  color: #777e90;
}
.icon-fb-b:before {
  content: "\e911";
  color: #777e90;
}
.icon-date:before {
  content: "\e92c";
  color: #777e90;
}
.icon-upload:before {
  content: "\e930";
  color: #777e90;
}
.icon-list:before {
  content: "\e90c";
  color: #059e4b;
}
.icon-calendar:before {
  content: "\e90d";
  color: #059e4b;
}
.icon-add-event:before {
  content: "\e90e";
  color: #777e90;
}
.icon-pin:before {
  content: "\e90a";
  color: #777e90;
}
.icon-clock:before {
  content: "\e90b";
  color: #777e90;
}
.icon-youtube:before {
  content: "\e909";
  color: #777e90;
}
.icon-check:before {
  content: "\e908";
  color: #059e4b;
}
.icon-logout:before {
  content: "\e907";
  color: #777e90;
}
.icon-email:before {
  content: "\e904";
  color: #777e90;
}
.icon-lock:before {
  content: "\e906";
  color: #777e90;
}
.icon-play:before {
  content: "\e903";
  color: #059e4b;
}
.icon-bookmark:before {
  content: "\e901";
  color: #fff;
}
.icon-share:before {
  content: "\e902";
  color: #fff;
}
.icon-views:before {
  content: "\e905";
  color: #fff;
}
.icon-comments:before {
  content: "\e924";
  color: #777e90;
}
.icon-fb:before {
  content: "\e925";
  color: #777e90;
}
.icon-ins:before {
  content: "\e93e";
  color: #777e90;
}
.icon-likes:before {
  content: "\e93f";
  color: #777e90;
}
.icon-snap:before {
  content: "\e940";
  color: #777e90;
}
.icon-tw:before {
  content: "\e941";
  color: #777e90;
}
.icon-search:before {
  content: "\e900";
  color: #777e90;
}
