@use '../../../assets/helpers/theme-variables' as v;
@import '../../../assets/helpers/mixins';

.hksa-google-news-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    margin-block-start: 50px;
    .hksa-news-wrapper {
        position: relative;
        width: 100%;
        ul {
            background-color: transparent;
            padding: 0;
            position: relative;
            display: grid;
            text-align: center;
            align-content: center;
            justify-content: center;
            grid-auto-flow: row dense;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 30px 32px;
            @media (max-width: 575px) {
                grid-template-columns: repeat(1, 1fr);
            } 
            .hksa-news-item {
                background-color: v.$blue-light;
                padding: 30px;
                border-radius: 18px;
                .hksa-news-item-header {
                    display: flex;
                    align-items: flex-start;
                    @media (max-width: 575px) {
                        flex-wrap: wrap;
                    } 
                    .hksa-news-item--media {
                        flex: 1;
                        @media (max-width: 575px) {
                            flex: auto;
                            width: 100%;
                            margin-block-end: 15px;
                        } 
                        a {
                            display: block;
                            background-image: url('../../../assets/img/hashksa-placeholder.jpg');
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-position: center center;
                            border-radius: 18px;
                            width: 100%;
                            img {
                                width: 100%;
                            }
                        }
                    }
                    .hksa-news-item--content {
                        text-align: start;
                        flex: 2;
                        padding: 0 30px;
                        @media (max-width: 575px) {
                            padding: 0;
                        } 
                        .hksa-news-block-meta {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-block-start: 15px;
                            width: 100%;
                            .hksa-news-item--date {
                                display: flex;
                                flex-wrap: wrap;
                                padding: 10px 15px;
                                background: #FFF;
                                border-radius: 19px;
                                opacity: 1;
                                backdrop-filter: blur(7px);
                                -webkit-backdrop-filter: blur(7px);
                                justify-content: center;
                                text-align: center;
                                span {
                                    display: block;
                                    width: 100%;
                                    color: v.$primary;
                                    font-size: v.$font-size-16;
                                    font-weight: 500;
                                    margin-block-end: 0;
                                    margin-inline-start: 0;
                                }
                            }
                            span {
                                display: flex;
                                align-items: center;
                                color: v.$text;
                                font-size: v.$font-size-16;
                                span {
                                    margin-inline-start: 10px;
                                }
                            }
                        }
                        .hksa-news-title {
                            font-size: v.$font-size-h4;
                            line-height: 1.3;
                            margin-block-end: 15px;
                            @media (max-width: 575px) {
                                font-size: 24px;
                            } 
                        }   
                    }
                }
                .hksa-news-item-footer {                    
                    p {
                        font-size: v.$font-size-18;
                        text-align: start;
                        margin-block-start: 20px;
                        margin-block-end: 0;
                    }
                }
            }
        }
    }
}