@use '../assets/helpers/theme-variables' as v;
@import '../assets/helpers/mixins';

.hksa-social-share {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    left: 30px;
    bottom: 30px;
    .hksa-social-share-item {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 15px;
        border: 1px solid transparent;
        background-color: v.$primary;
        border-radius: 14px;
        width: 58px;
        height: 58px;
        font-size: 16px;
        color: #777E90;
        margin: 0;
        z-index: 1;
        cursor: pointer;
        i {
            color: v.$white;
            font-size: 22px;
            font-weight: 500;
            &::before {
                color: v.$white;
            }
        }
    }
    &.dark {
        display: flex;
        width: auto;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        left: 0px;
        bottom: 0px;
        .hksa-social-share-item {
            background-color: rgb(65 65 65 / 57%);
            border-radius: 14px;
            width: 60px;
            height: 60px;
            align-items: center;
            text-align: center;
            justify-content: center !important;
            margin-inline-end: 0;
            margin-block: 0;
            border: 0;
            i {
                margin: 0;
                &::before {
                    color: v.$white;
                }
            }
        }
    }
    &.light {
        display: flex;
        width: auto;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        left: 0px;
        bottom: 0px;
        // .hksa-social-share-item {
        //     background-color: rgb(65 65 65 / 57%);
        //     border-radius: 14px;
        //     width: 60px;
        //     height: 60px;
        //     align-items: center;
        //     text-align: center;
        //     justify-content: center !important;
        //     margin-inline-end: 0;
        //     margin-block: 0;
        //     border: 0;
        //     i {
        //         margin: 0;
        //         &::before {
        //             color: v.$white;
        //         }
        //     }
        // }
    }
    &.relative {
        position: relative;
        left: 0;
        bottom: 0;
        .hksa-social-share-item {
            border: 1px solid #EFF0F0;
        }
    }
}
.hksa_share_container {
    display: flex;
    justify-content: center;
    .hksa__some-network {
        vertical-align: top;
        display: inline-block;
        margin-right: 10px;
        text-align: center;
        .hksa__some-network__share-count {
            display: inline-flex;
            justify-content: center;
            white-space: nowrap;
            overflow: visible;
            width: 0;
            margin-top: 3px;
            font-size: 12px;
        }
    }
}

.hksa-news-meta-item {
    &.like {
        .large {
            font-size: 25px;
            margin-inline-end: 10px;
        }
    }
}