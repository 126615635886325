@import "./assets/main.scss";
@import "./assets/fonts/din-next/stylesheet.scss";
@import "./assets/fonts/icons/style.scss";
@import "react-leaf-polls/dist/index.css";
@import "tippy.js/dist/tippy.css";
@import "react-datepicker/dist/react-datepicker.css";

body {
  background-color: #f4f7f9;
  font-family: "sans-serif", "DIN NEXTTM ARABIC";
  &.sidemenu-active {
    overflow: hidden;
  }
}

.container-fluid {
  max-width: 1560px;
  min-width: 320px;
}
