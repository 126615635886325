@use '../../assets/helpers/theme-variables' as v;

.trend-bllocks-wrapper {
    .trend-tabs-wrapper {
        .nav-tabs {
            border-bottom: 0;
            li {
                margin: 0 10px;
              @media (max-width: 575px) {
                    margin-block-end: 10px;
                } 
                button {
                    background-color: transparent;
                    box-shadow: none;
                    border: 1px Solid #E7E7E7;
                    padding: 20px;
                    border-radius: 15px;
                    span {
                        display: inline-flex;
                        flex-direction: row-reverse;
                        font-size: 21px;
                        color: #777E90;
                        @media only screen and (min-width: 420px) and (max-width: 960px) {
                            font-size: 18px;
                        }
                        i {
                            margin-inline-end: 10px;
                        }
                        img {
                            height: 20px;
                            margin-inline-end: 10px;
                            filter: grayscale(100%);
                        }
                    }
                    &.active {
                        background-color: #EBF1F4;
                        border-color: #EBF1F4;
                        span {
                            font-weight: 500;
                            color: v.$theme-black;
                            img {
                                margin-inline-end: 10px;
                                filter: grayscale(0);
                            }
                        }
                        .icon-tw {
                            &:before {
                                color: #09a9f3;
                            }
                        }
                        .icon-youtube {
                            &:before {
                                color: #D62025;
                            }
                        }
                    }
                }
            }
        }
    }
}