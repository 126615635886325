@use "../../assets/helpers/theme-variables" as v;
@import "../../assets/helpers/mixins";

.hkas-bookmark-details {
    min-height: calc(100vh - 640px);
    margin-block-start: 40px;
    .nav-tabs {
      position: relative;
      display: grid;
      text-align: center;
      align-content: center;
      justify-content: center;
      grid-auto-flow: row dense;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 30px 32px;
      border-bottom: 0;
      @media (max-width: 575px) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media only screen and (min-width: 300px) and (max-width: 960px) {
        grid-template-columns: repeat(2, 1fr);
      }
      li {
        button {
          background-color: transparent;
          box-shadow: none;
          border: 1px Solid #e7e7e7 !important;
          padding: 20px;
          border-radius: 15px !important;
          span {
            font-size: 21px;
            color: #777e90;
            i {
              margin-inline-end: 10px;
            }
          }
          &.active {
            background-color: #ebf1f4 !important;
            border-color: #ebf1f4 !important;
            span {
              font-weight: 500;
              color: v.$theme-black;
            }
            .icon-tw {
              &:before {
                color: #09a9f3;
              }
            }
            .icon-youtube {
              &:before {
                color: #d62025;
              }
            }
          }
        }
      }
    }
    
    .tab-content {
      @media (max-width: 575px) {
        padding: 0;
      }
      @media only screen and (min-width: 300px) and (max-width: 960px) {
        padding: 0;
      }
      .hksa-page-bookmark-posts {
        padding-block: 0px 70px;
        .hkas-bookmark-details {
          .container-fluid {
            @media (max-width: 575px) {
              padding: 0;
            }
            @media only screen and (min-width: 300px) and (max-width: 960px) {
              padding: 0;
            }
          }
          .hkas-bookmark-posts-items {
            @media (max-width: 575px) {
              grid-template-columns: repeat(1, 1fr);
            }
            @media only screen and (min-width: 300px) and (max-width: 960px) {
              grid-template-columns: repeat(1, 1fr);
            }
            .hksa-news-block {
              max-height: 400px;
              img {
                object-fit: cover;
                width: 100%;
                height: 100%;
              }
              .hksa-news-block-header {
                .hksa-news-item--cat {
                  img {
                    width: auto;
                  }
                }
                .hksa-play {
                  width: 58px;
                  height: 58px;
                  i {
                    font-size: v.$font-size-16 
                  }
                }
                .hksa-events-item--date {
                    display: flex;
                    flex-wrap: wrap;
                    width: 110px;
                    height: 126px;
                    background-color: v.$primary;
                    border-radius: 16px;
                    padding: 20px 0;
                    justify-content: center;
                    text-align: center;
                    span {
                      display: block;
                      width: 100%;
                      color: v.$white;
                      font-size: v.$font-size-h1;
                      font-weight: 700;
                      &:first-child {
                        font-size: v.$font-size-16;
                        margin-block-start: 15px;
                        margin-block-end: -10px;
                        font-weight: 500;
                      }
                    }
                  }
              }
              .hksa-news-block-footer {
                .hksa-news-block-footer-info {
                    a {
                        .hksa-news-title {
                            font-size: v.$font-size-20; 
                            line-height: 1.5;
                        }
                    }
                }
                .hksa-news-meta-block {
                    margin: 0;
                    .hksa-event-description {
                        font-size: v.$font-size-16;
                        color: v.$white;
                        margin: 0;
                    }
                    .hksa-news-meta {
                        flex: 2;
                        &.align-items-end {
                            flex: 1;
                        }
                    }
                }
              }
            }
          }
        }
      }
    }
}

.hkas-bookmark-posts-items {
  position: relative;
  display: grid;
  align-content: center;
  justify-content: center;
  grid-auto-flow: row dense;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px 32px;
}
