@use '../../../assets/helpers/theme-variables' as v;
@import '../../../assets/helpers/mixins';

.hksa-youtube-trend-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    margin-block-start: 50px;
    .hksa-youtube-trend-container {
        .hksa-youtube-trend-lists {
            position: relative;
            display: grid;
            text-align: center;
            align-content: center;
            justify-content: center;
            grid-auto-flow: row dense;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 30px 32px;
          @media (max-width: 575px) {
                grid-template-columns: repeat(1, 1fr);
            }
            .hksa-youtube-trend-list {
                position: relative;
                .hksa-youtube-trend-list-header {
                    position: relative;
                    max-height: 600px;
                    height: 420px;
                    border-radius: 18px;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 135%;
                        object-fit: cover;
                        object-position: center -73px;
                    }
                    .hksa-play {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @include vertical-position();
                        right: 0;
                        left: 0;
                        margin: auto;
                        background: v.$white;
                        width: 78px;
                        height: 78px;
                        border-radius: 100%;
                        cursor: pointer;
                        z-index: 11;
                        i {
                            font-size: v.$font-size-20;
                        }
                    }
                }
                .hksa-youtube-trend-list-content {
                    position: absolute;
                    width: 100%;
                    right: 0;
                    bottom: 0;
                    z-index: 1;
                    padding: 30px;
                    .hksa-youtube-trend-list-content-title {
                        display: flex;
                        width: 100%;
                        align-items: center;
                        justify-content: flex-start;
                        text-align: start;
                        padding-block-end: 20px;
                        border-bottom: 1px Solid rgb(255 255 255 / 27%);
                        margin-block-end: 20px;
                        h4 {
                            font-size: v.$font-size-h4;
                            color: v.$white;
                            direction: ltr;
                            text-align: right;
                            line-height: 1.5;
                            direction: rtl;
                            @media only screen and (max-width: 576px) {
                                font-size: v.$font-size-20;
                            }
                            @media only screen and (min-width: 575px) and (max-width: 960px) {
                                font-size: v.$font-size-20;
                            }
                        }
                    }
                    .hksa-youtube-trend-list-info {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        .hksa-youtube-trend-list-info-names {
                            display: flex;
                            align-items: center;
                            img {
                                width: 45px;
                                height: 45px;
                                border-radius: 50px;
                            }
                            .hksa-list-info-names {
                                margin-inline-start: 20px;
                                @media only screen and (max-width: 576px) {
                                    text-align: start;
                                }
                                @media only screen and (min-width: 575px) and (max-width: 960px) {
                                    text-align: start;
                                }
                                h5 {
                                    font-size: v.$font-size-16;
                                    color: v.$white;
                                    margin-block-end: 10px;
                                }
                                span {
                                    font-size: v.$font-size-16;
                                    color: v.$white;
                                }
                            }
                        }
                        .hksa-youtube-trend-list-date {
                            font-size: v.$font-size-16;
                            color: v.$white;
                        }
                    }
                }
                &:after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    right: 0;
                    top: 0;
                    background: transparent linear-gradient(0deg, #000000D1 0%, #181818B8 35%, #4C4C4C00 82%, #5454540D 100%) 0% 0% no-repeat padding-box;
                    border-radius: 18px;
                }
            }
        }
    }
}

.hksa-youtube-modal {
    .modal-content {
        padding: 0;
        .modal-header {
            margin-block-end: 0;
            button {
                position: absolute;
                right: -30px;
                top: 0;
                background-color: v.$white;
                opacity: 1;
                right: -15px;
                top: -15px;
                border-radius: 50%;
                z-index: 1;
            }
        }
        .modal-body {
            padding: 0;
            iframe {
                max-width: 100%;
                width: 100%;
                height: 400px;
            }
            .hksa-youtube-trend-list-content {
                position: relative;
                width: 100%;
                right: 0;
                bottom: 0;
                z-index: 1;
                padding: 30px;
                .hksa-youtube-trend-list-content-title {
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: flex-start;
                    text-align: start;
                    padding-block-end: 20px;
                    border-bottom: 1px Solid rgba(68, 68, 68, 0.27);
                    margin-block-end: 20px;
                    h4 {
                        font-size: v.$font-size-h4;
                        color: v.$theme-black;
                        direction: ltr;
                        text-align: right;
                        line-height: 1.5;
                        direction: rtl;
                    }
                }
                .hksa-youtube-trend-list-info {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .hksa-youtube-trend-list-info-names {
                        display: flex;
                        align-items: center;
                        img {
                            width: 45px;
                            height: 45px;
                            border-radius: 50px;
                        }
                        .hksa-list-info-names {
                            margin-inline-start: 20px;
                            h5 {
                                font-size: v.$font-size-16;
                                color: v.$theme-black;
                                margin-block-end: 10px;
                            }
                            span {
                                font-size: v.$font-size-16;
                                color: v.$theme-black;
                            }
                        }
                    }
                    .hksa-youtube-trend-list-date {
                        font-size: v.$font-size-16;
                        color: v.$theme-black;
                    }
                }
            }
        }
    }
}