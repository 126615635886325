//Colors
$primary:#059E4B;
$theme-black: #253031;
$text: #777E90;
$blue-light: #EBF1F4;
$white: #FFF;


//Social colors
$fb: #3b59b6;
$tw: #4099ff;
$spotify: #81b71a;
$google: #ea4335;
$instagram: #c13584;
$snapchat: #FEEA3B;

//Font Size
$font-size-10: 10px !default; // ~10px
$font-size-12: 12px !default; // ~12px
$font-size-13: 13px !default; // ~13px
$font-size-14: 14px !default; // ~14px
$font-size-16: 16px !default; // ~16px
$font-size-18: 18px !default; // ~18px
$font-size-20: 20px !default; // ~20px
$font-size-22: 22px !default; // ~22px

$font-size-h1: 38px !default; // ~38px
$font-size-h2: 32px !default; // ~32px
$font-size-h3: 27px !default; // ~27px
$font-size-h4: 23px !default; // ~23px
$font-size-h5: $font-size-18 !default;
$font-size-h6: 16px !default; // ~16px
