@use "../../assets/helpers/theme-variables" as v;

.footer-wrapper {
  background: #fff;
  padding: 30px 0;
  .logo {
    @media (max-width: 575px) {
      margin-block-end: 15px;
      justify-content: center !important;
    }
    @media only screen and (min-width: 575px) and (max-width: 960px) {
        margin-block-end: 15px;
        justify-content: center !important;
    }
    a {
      display: block;
      width: 100%;        
      img {
        margin-inline-end: 25px;
        width: 60%;
      }
    }
    .logo-heading {
      h4 {
        font-size: v.$font-size-h1;
        color: v.$primary;
        margin-block: 0;
      }
      span {
        font-size: v.$font-size-20;
        color: v.$text;
      }
    }
  }
  .nav {
    @media (max-width: 575px) {
      justify-content: center !important;
      margin-block-end: 15px;
    }
    @media only screen and (min-width: 575px) and (max-width: 960px) {
        justify-content: center !important;
        margin-block-end: 15px;
    }
    .nav-item {
      padding-inline: 5px;
      a {
        color: v.$primary;
        font-size: v.$font-size-20;
        @media (max-width: 575px) {
          font-size: v.$font-size-14;
        }
        @media only screen and (min-width: 959px) and (max-width: 1300px) {
          font-size: v.$font-size-14;
          padding-right: 0 !important;
          padding-left: 0 !important;
        }
        &:hover {
          color: v.$theme-black;
        }
      }
    }
  }
  .social-links {
    display: flex;
    align-items: center;
    @media (max-width: 575px) {
      justify-content: center !important;
      margin-block-start: 15px;
    }
    @media only screen and (min-width: 575px) and (max-width: 960px) {
        justify-content: center !important;
        margin-block-end: 15px;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 70px;
      border-radius: 14px;
      margin-inline-end: 20px;
      @media (max-width: 575px) {
        width: 40px;
        height: 40px;
      }
      @media only screen and (min-width: 959px) and (max-width: 1300px) {
        width: 40px;
        height: 40px;
      }
    }
  }
}
